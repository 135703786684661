
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'Success',
  setup () {
    const router = useRouter()
    const backHome = () => {
      router.push('/')
    }
    return {
      backHome
    }
  }
})
